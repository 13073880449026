import Instance from "./instance";

export const getAudioLibrary = async () => {
  console.log("method called");
  try {
    return await Instance.instanceToken.get("/audiolib");
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addAudio = async (payload) => {
  console.log(payload);
  try {
    return await Instance.instanceToken.post("/audiolib", payload);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteAudio = async (id) => {
  console.log(id);
  try {
    return await Instance.instanceToken.delete(`/audiolib/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
