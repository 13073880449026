import Instance from "./instance";

export const GetAllTargetVideos = async (params) => {
  try {
    return await Instance.instanceToken.get(`/targetVideos`, {
      params
    });
  } catch (error) {
    return error;
  }
};

export const WaitForConversion = async (videoId) => {
  return await Instance.instanceToken.get(`/targetVideos/wait/${videoId}`);
}

export const CreateTargetVideo = async (payload, onUploadProgress) => {
  try {
    const config = { onUploadProgress }
    return await Instance.instanceFileUpload.post(`/targetVideos`, payload, config);
  } catch (error) {
    return error;
  }
};

export const GetSingleTargetVideo = async (id) => {
  try {
    return await Instance.instanceToken.get(`/targetvideos/${id}`);
  } catch (error) {
    return error;
  }
};

export const UpdateTargetVideo = async (id, payload, onUploadProgress) => {
  try {
    const config = { onUploadProgress }
    return await Instance.instanceToken.put(`/targetvideos/${id}`, payload, config);
  } catch (error) {
    return error;
  }
};

export const DeleteTargetVideo = async (id) => {
  try {
    return await Instance.instanceToken.delete(`/targetvideos/${id}`);
  } catch (error) {
    return error;
  }
};

export const EnableDisableVideo = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/targetvideos/toggleStatus`, payload);
  } catch (error) {
    return error;
  }
}