<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 card-row"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden shadow-sm border-0 w-100 card-container"
        >
          <!-- Add Target Video form -->
          <form
            id="new-target-video-form"
            class="d-flex flex-row flex-wrap align-items-start justify-content-start mx-0 px-0 py-0 h-100"
          >
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                v-if="!isEdit"
                class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
              >
                Add a new video
              </h6>
              <h6
                v-else
                class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
              >
                Edit Video
              </h6>
              <!-- full row input (Video Title) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="videoTitleError.status"
                    :invalidFeedback="`${videoTitleError.message}`"
                    label="Video Title"
                    v-model="videoTitle"
                    isRequired
                    trim
                    :counter="28"
                    form="new-target-video-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Video File Input) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <b-col cols="12" class="mb-2">
                  <FormFile
                    ref="videoUpload"
                    groupId="video-file-group"
                    id="video-file-group-input"
                    class="text-prime-gray"
                    :placeholder="
                      videoFile ? getFileName(videoFile) : 'Video File'
                    "
                    :state="videoFileError.status"
                    :invalidFeedback="`${videoFileError.message}`"
                    description="You can only upload .mp4, .avi, .mkv, .mov files less than 25MB as videos"
                    v-model="videoFile"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="file"
                  ></FormFile>
                </b-col>
              </b-row>
              <!-- full row input (Video Preview Image File Input) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <b-col cols="12" class="my-2">
                  <FormFile
                    ref="imageUpload"
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :placeholder="
                      videoPreviewImage
                        ? getFileName(videoPreviewImage)
                        : 'Video Preview Image'
                    "
                    :state="videoPreviewImageError.status"
                    :invalidFeedback="`${videoPreviewImageError.message}`"
                    description="You can upload JPEG or PNG files as video preview images "
                    accept="image/jpeg, image/png"
                    v-model="videoPreviewImage"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="file"
                  ></FormFile>
                </b-col>
              </b-row>
              <!-- Audio Chose -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
                no-gutters
              >
                <b-col cols="12" class="mb-2">
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 py-0 m-0 filter-dropdown-element"
                    toggle-class="border bg-white rounded-lg"
                    menu-class="w-100 bg-white border border-prime-gray py-0 filter-dropdown-menu thin-scrollbar"
                    left
                    no-caret
                    no-flip
                  >
                    <template v-slot:button-content>
                      <div
                        class="d-flex align-items-center justify-content-between w-100 filter-dropdown-button-element"
                      >
                        <span class="text-left text-prime-gray font-primary">
                          {{
                            selectedAudio != null
                              ? selectedAudio.title
                              : "Select an Audio"
                          }}
                        </span>
                        <font-awesome-icon
                          class="text-prime-gray px-0 w-auto ml-2"
                          icon="angle-down"
                          aria-label="Help"
                        ></font-awesome-icon>
                      </div>
                    </template>
                    <b-dropdown-group flush class="rounded-lg py-0">
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-center w-100"
                        no-gutters
                      >
                      </b-row>
                      <div class="w-100 account-dropdown">
                        <template>
                          <b-row
                            class="w-100 d-flex flex-row align-items-center justify-content-start"
                            no-gutters
                          >
                            <b-col
                              class="w-100 d-flex flex-column align-items-stretch justify-content-center px-0"
                            >
                              <!-- list all the suppliers here -->
                              <template v-for="(item, index) in audio">
                                <b-dropdown-item-button
                                  :key="index"
                                  @click="changeAudio(item), (selected = item)"
                                  class="w-100"
                                  button-class="d-flex flex-column align-items-start justify-content-center px-3 py-2 dropdown-item-button"
                                >
                                  <span
                                    class="text-left text-dark font-primary"
                                  >
                                    {{ item.title }}
                                  </span>
                                </b-dropdown-item-button>
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                    </b-dropdown-group>
                  </b-dropdown>
                </b-col>
                <!-- <span class="text-danger" v-if="audioError"
                  >Please select an audio</span
                > -->
              </b-row>
              <!-- Font  Choose -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
                no-gutters
              >
                <b-col cols="12" class="mb-2">
                  <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 py-0 m-0 filter-dropdown-element"
                    toggle-class="border bg-white rounded-lg"
                    menu-class="w-100 bg-white border border-prime-gray py-0 filter-dropdown-menu thin-scrollbar"
                    left
                    no-caret
                    no-flip
                  >
                    <template v-slot:button-content>
                      <div
                        class="d-flex align-items-center justify-content-between w-100 filter-dropdown-button-element"
                      >
                        <span
                          class="text-left text-prime-gray font-primary"
                          v-bind:style="{ fontFamily: selected }"
                        >
                          {{
                            selected && selected.family != "null"
                              ? selected.family
                              : "Select Font"
                          }}
                        </span>
                        <font-awesome-icon
                          class="text-prime-gray px-0 w-auto ml-2"
                          icon="angle-down"
                          aria-label="Help"
                        ></font-awesome-icon>
                      </div>
                    </template>
                    <b-dropdown-group flush class="rounded-lg py-0">
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-center w-100"
                        no-gutters
                      >
                      </b-row>
                      <div class="w-100 account-dropdown">
                        <template>
                          <b-row
                            class="w-100 d-flex flex-row align-items-center justify-content-start"
                            no-gutters
                          >
                            <b-col
                              class="w-100 d-flex flex-column align-items-stretch justify-content-center px-0 index"
                            >
                              <!-- list all the suppliers here -->
                              <template v-for="(item, index) in fonts">
                                <b-dropdown-item-button
                                  :key="index"
                                  class="w-100 fixed"
                                  @click="changeFont(item), (selected = item)"
                                  button-class="d-flex flex-column align-items-start justify-content-center px-3 py-2 dropdown-item-button"
                                >
                                  <span
                                    class="text-left text-dark font-primary"
                                    v-bind:style="{
                                      fontFamily: item.family,
                                    }"
                                  >
                                    {{ item.family }}
                                  </span>
                                </b-dropdown-item-button>
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                    </b-dropdown-group>
                  </b-dropdown>
                </b-col>
                <!-- <span class="text-danger" v-if="fontError"
                  >Please select a font type</span
                > -->
              </b-row>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
              >
                Video Preview
              </h6>
              <!-- full row image uploader -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100 video-preview-row"
                no-gutters
              >
                <b-col cols="12">
                  <video
                    ref="videoRef"
                    id="video-container"
                    :class="!videoFile || !playable ? 'd-none' : ''"
                    width="100%"
                    controls
                    :src="videoSrc"
                  ></video>
                  <template v-if="!videoFile || !playable">
                    <div
                      class="d-flex flex-row align-items-center justify-content-center border border-ghost-white w-100 video-placeholder"
                    >
                      <h6 class="text-center py-3 mx-auto">
                        {{ videoPreviewMsg }}
                        <span class="d-block text-muted mt-2"
                          >(Supported format: .mp4, .avi, .mkv, .mov)</span
                        >
                      </h6>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-column align-items-end justify-content-end align-self-end mb-2"
            >
              <!-- form action -->
              <b-row
                class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-end mt-3 w-100"
                no-gutters
              >
                <!-- cancel button -->
                <b-col
                  class="d-flex flex-column align-items-center justify-content-center pr-0 pr-md-2 py-2"
                  cols="12"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <FormButton
                    variant="ghost-white"
                    isBlock
                    class="text-dark"
                    type="reset"
                    @click.native="resetFormFn()"
                    >Reset</FormButton
                  >
                </b-col>
                <!-- save and exit button -->
                <b-col
                  class="d-flex flex-column align-items-center justify-content-center pl-0 pl-md-2 py-2"
                  cols="12"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <FormButton
                    id="save-exit-btn"
                    variant="primary"
                    isBlock
                    :disabled="isAPILoading"
                    :isLoading="isAPILoading"
                    :loadingText="uploadProgress"
                    class="text-white"
                    type="submit"
                    @click.native="videoCreateFn()"
                    v-b-popover.hover
                    :title="apiConfirmationText"
                    >Save and Exit</FormButton
                  >
                  <b-tooltip
                    id="helper-tooltip"
                    target="save-exit-btn"
                    triggers="manual"
                    placement="top"
                    boundary="window"
                    offset="0"
                  >
                    {{ apiConfirmationText }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-col>
          </form>
        </b-card>
      </b-row>
      <b-overlay :show="initLoading" no-wrap></b-overlay>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";

// components
import FormInput from "@/components/Form/FormInput";
import FormFile from "@/components/Form/FormFile";
import FormButton from "@/components/Form/FormButton";

//services
import {
  CreateTargetVideo,
  GetSingleTargetVideo,
  UpdateTargetVideo,
} from "@/services/targetVideos.service";

import { getAudioLibrary } from "@/services/audioLibrary";
import fonts from "@/data/fonts.js"

console.log(fonts)

export default {
  name: "VideosCreate",
  components: {
    HomeLayout,
    FormInput,
    FormFile,
    FormButton,
  },
  data() {
    return {
      audio: [],
      fonts: fonts,
      selectedFont: null,
      selectedAudio: null,
      videoTitle: null,
      videoFile: null,
      newUpload: true,
      videoPreviewImage: null,
      isAPILoading: false,
      initLoading: false,
      isEdit: false,
      playable: null,
      videoPreviewMsg: "Upload your video file to see a preview here.",
      apiConfirmationText: null,
      videoSrc: null,
      videoTitleError: {
        status: null,
        message: "",
      },
      videoFileError: {
        status: null,
        message: "",
      },
      videoPreviewImageError: {
        status: null,
        message: "",
      },
      audioSelectError: {
        status: null,
        message: "",
      },
      fontSelectError: {
        status: null,
        message: "",
      },
      uploadProgress: "Uploading",
      tempVideoName: null,
    };
  },
  mounted() {
    if (this.$route.meta.isEdit) {
      this.isEdit = true;
      this.initEditTargetVideoFn(this.$route.params.id);
    }
    this.fetchAudioList();
  },
  watch: {
    videoTitle(val) {
      if (val) {
        this.videoTitleError.status = true;
        if (val.length <= 28) {
          this.videoTitleError.status = true;
        } else {
          this.videoTitleError.status = false;
          this.videoTitleError.message =
            "Video Title should have less than 28 characters";
        }
      } else {
        this.videoTitleError.status = false;
        this.videoTitleError.message = "Please fill in the Video Title";
      }
    },
    videoFile(val) {
      this.newUpload = true;

      // validate if it is a file
      if (
        "File" in window &&
        val instanceof File &&
        val.type.split("/")[0] == "video"
      ) {
        const a = document.createElement("video");
        const canPlay = a.canPlayType(val.type);
        this.playable = canPlay == "" ? false : true;
        this.videoPreviewMsg =
          "Sorry preview is not available. You can still proceed.";
        // check if it is a mp4 video file
        if (val.size && val.size < 55 * 1024 * 1024) {
          // if (val.type && val.type == "video/mp4") {
          // check if the video's size is less than 25mb
          // get reference to the video container
          let videoContainer = this.$refs.videoRef;

          // instantialize the file reader and add onload event
          const reader = new FileReader();
          reader.onload = (e) => {
            videoContainer.src = e.target.result;
          };

          // read the file as a data url
          reader.readAsDataURL(val);

          // clear any error and mark form file as valid
          this.videoFileError.status = true;
          this.videoFileError.message = "";
        } else if (!this.isEdit) {
          this.videoFileError.status = false;
          this.videoFileError.message =
            "Please upload a video file that is less than 25MB in size";
        }
        // }
        //  else {
        //   this.videoFileError.status = false;
        //   this.videoFileError.message =
        //     "Please upload a valid video file in the .mp4 format";
        // }
      } else {
        this.videoPreviewMsg = "Upload your video file to see a preview here.";
        this.videoFileError.status = false;
        this.videoFileError.message = "Please upload a valid file";
      }
    },
    videoPreviewImage(val) {
      // validate if it is a file
      if ("File" in window && val instanceof File) {
        // check if it is an image file of type png or jpeg
        if ((val.type && val.type == "image/jpeg") || val.type == "image/png") {
          // check if the video's size is less than 5mb
          if (val.size && val.size < 5 * 1024 * 1024) {
            // get reference to the video container
            let videoContainer = this.$refs.videoRef;

            // instantialize the file reader and add onload event
            const reader = new FileReader();
            reader.onload = (e) => {
              videoContainer.poster = e.target.result;
              // that.videoPreviewImageFile = e.target.result;
            };

            // read the file as a data url
            reader.readAsDataURL(val);

            // clear any error and mark form file as valid
            this.videoPreviewImageError.status = true;
            this.videoPreviewImageError.message = "";
          } else {
            this.videoPreviewImageError.status = false;
            this.videoPreviewImageError.message =
              "Please upload an image file that is less than 5MB in size";
          }
        } else {
          this.videoPreviewImageError.status = false;
          this.videoPreviewImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";
        }
      } else {
        this.videoPreviewImageError.status = false;
        this.videoPreviewImageError.message = "Please upload a valid file";
      }
    },
  },
  computed: {
    computeVideoTitleValidation() {
      return this.videoTitle && this.videoTitle.length <= 28 ? true : false;
    },
    computeVideoFileValidation() {
      // check if the video file is a valid video file in the mp4 format
      if (!this.videoFile) return false;
      if (this.isEdit && !this.newUpload) return true;
      if (
        "File" in window &&
        this.videoFile instanceof File &&
        // this.videoFile.type &&
        // this.videoFile.type == "video/mp4" &&
        this.videoFile.size &&
        this.videoFile.size < 25 * 1024 * 1024
      ) {
        return true;
      }
      return false;
    },
    computeVideoPreviewImageValidation() {
      // check if the image file is a valid image file with the supported format (jpeg/png)
      if (this.videoPreviewImage) {
        if (
          "File" in window &&
          this.videoPreviewImage instanceof File &&
          this.videoPreviewImage.type &&
          (this.videoPreviewImage.type == "image/jpeg" ||
            this.videoPreviewImage.type == "image/png") &&
          this.videoPreviewImage.size &&
          this.videoPreviewImage.size < 5 * 1024 * 1024
        ) {
          return true;
        }
        return false;
      } else return false;
    },
    audioSelectValidation() {
      if (!this.selectedAudio) return false;
      return true;
    },
    fontSelectValidation() {
      if (!this.selectedFont) return false;
      return true;
    },
  },
  methods: {
    async fetchAudioList() {
      const res = await getAudioLibrary();
      this.audio = res.data.content.lib.filter((a) => a.ready);
    },
    async initEditTargetVideoFn(val) {
      let id = val;
      try {
        this.initLoading = true;
        let { data } = await GetSingleTargetVideo(id);
        if (data.code == 200 || data.message == "success") {
          let result = data.content;
          this.videoTitle = result.videoTitle;
          this.videoSrc = result.video;
          this.videoFile = await this.getFileFromURL(
            result.video,
            "video.mp4",
            "video/mp4"
          );
          this.tempVideoName = await this.getFileFromURL(
            result.video,
            "video.mp4",
            "video/mp4"
          );
          this.videoPreviewImage = await this.getFileFromURL(
            result.videoPreviewImage,
            "videoPreviewImage.jpeg",
            "image/jpeg",
            false
          );

          // Set Audio
          if (result.audio) {
            this.selectedAudio = this.audio.find((a) => a.src == result.audio);
            console.log("Audio selec", this.selectedAudio);
          }
          if (result.font) {
            this.selectedFont = this.fonts.find(
              (f) => f.files.regular == result.font
            );
            console.log("Font selec", this.selectedFont);
          }
          this.newUpload = false;
          this.initLoading = false;
        } else {
          this.initLoading = false;
        }
      } catch (error) {
        this.initLoading = false;
      }
    },
    changeAudio(audio) {
      this.selectedAudio = audio;
      console.log("Selected ", audio);
    },
    changeFont(font) {
      this.selectedFont = font;
      console.log("Selected ", font);
    },
    async videoCreateFn() {
      if (
        this.computeVideoTitleValidation &&
        this.computeVideoFileValidation &&
        this.computeVideoPreviewImageValidation &&
        this.audioSelectValidation &&
        this.fontSelectValidation
      ) {
        try {
          this.isAPILoading = true;
          const formData = new FormData();
          formData.append("video", this.videoFile);
          formData.append("videoTitle", this.videoTitle);
          formData.append("videoPreviewImage", this.videoPreviewImage);
          formData.append("audio", this.selectedAudio.src);
          formData.append("font", this.selectedFont.files.regular);

          // set tootlip text
          if (this.isAPILoading) {
            this.apiConfirmationText =
              "Please wait! This might take a few seconds";
            this.$root.$emit("bv::show::tooltip", "helper-tooltip");
          }

          if (this.isEdit) {
            const formData1 = new FormData();
            if (this.tempVideoName.name != this.videoFile.name) {
              formData1.append("video", this.videoFile);
            }
            formData1.append("videoTitle", this.videoTitle);
            formData1.append("videoPreviewImage", this.videoPreviewImage);

            if (this.selectedAudio)
              formData1.append("audio", this.selectedAudio.src);
            if (this.selectedFont)
              formData1.append("font", this.selectedFont.files.regular);

            let id = this.$route.params.id;
            let { data } = await UpdateTargetVideo(
              id,
              formData1,
              this.updateProgress
            );
            if (data.code == 200 || data.message == "success") {
              this.isAPILoading = false;

              // hide the tooltip
              this.apiConfirmationText = null;
              this.$root.$emit("bv::hide::tooltip", "helper-tooltip");

              let payloadNotify = {
                isToast: true,
                title: "Video updated successfully",
                content: "The video has been updated successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);

              this.$router.push({ name: "Videos" });
            } else {
              this.isAPILoading = false;
              this.apiConfirmationText = null;
              let payloadNotify = {
                isToast: true,
                title: "ERROR! Unable to update video",
                content: data.details,
                variant: "danger",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
          } else {
            let { data } = await CreateTargetVideo(
              formData,
              this.updateProgress
            );
            if (data.code == 200 || data.message == "success") {
              this.isAPILoading = false;
              this.apiConfirmationText = null;

              let payloadNotify = {
                isToast: true,
                title: "Video added successfully",
                content: "The video has been added successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);

              this.$router.push({ name: "Videos" });
            } else {
              this.isAPILoading = false;
              this.apiConfirmationText = null;
              let payloadNotify = {
                isToast: true,
                title: "ERROR! Unable to add video",
                content: data.details,
                variant: "danger",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
          }
        } catch (error) {
          this.isAPILoading = false;
          this.apiConfirmationText = null;
        }
      } else {
        this.errorFn();
      }
    },
    updateProgress(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.uploadProgress = `Uploading ${percentCompleted}%`;
    },
    async getFileFromURL(url, name, defaultType = "image/jpeg", head = true) {
      if (url && url.length > 0) {
        try {
          const method = head ? "HEAD" : "GET";
          const response = await fetch(url, { method, headers: [] });

          const data = await response.blob();
          return new File([data], name, {
            type: response.headers.get("content-type") || defaultType,
          });
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    },
    // get file name from file object
    getFileName(object) {
      if (object) return object.name;
      return "Audio File";
    },
    errorFn() {
      // error list to have an array of all the errors to be displayed as a list
      let errorList = [];
      // use .push() to append string as an array element
      if (!this.selectedAudio) {
        errorList.push("Please select an audio ");
      }

      if (!this.selectedFont) {
        errorList.push("Please select a font type ");
      }
      if (!this.selectedAudio) {
        this.audioSelectError.status = false;
        this.audioSelectError.message = "Please select an audio";
      }
      if (!this.selectedFont) {
        this.fontSelectError.status = false;
        this.fontSelectError.message = "Please select a font";
      }

      // video title validation
      if (!this.videoTitle) {
        this.videoTitleError.status = false;
        this.videoTitleError.message = "Please fill in the video title";

        errorList.push("Please fill in the video title");
      } else if (this.videoTitle.length > 28) {
        this.videoTitleError.status = false;
        this.videoTitleError.message =
          "Title should have less than 28 characters";

        errorList.push("Title should have less than 28 characters");
      }

      // video file validation
      if (!this.videoFile) {
        this.videoFileError.status = false;
        this.videoFileError.message = "Please upload the video file";

        errorList.push("Please upload the video file");
      } else {
        if ("File" in window && this.videoFile instanceof File == false) {
          this.videoFileError.status = false;
          this.videoFileError.message =
            "Please upload a valid file as the video file";

          errorList.push("Please upload a valid file as the video file");
        }
        // if (this.videoFile.type && this.videoFile.type != "video/mp4") {
        //   this.videoFileError.status = false;
        //   this.videoFileError.message =
        //     "Please upload a valid video file in the .mp4 format";

        //   errorList.push("Please upload a valid video file in the .mp4 format");
        // }
        if (this.videoFile.size && this.videoFile.size > 25 * 1024 * 1024) {
          this.videoFileError.status = false;
          this.videoFileError.message =
            "Please upload a video file that is less than 25MB in size";

          errorList.push(
            "Please upload a video file that is less than 25MB in size"
          );
        }
      }

      // video preview image validation
      if (!this.videoPreviewImage) {
        this.videoPreviewImageError.status = false;
        this.videoPreviewImageError.message =
          "Please upload the video preview image";

        errorList.push("Please upload the video preview image");
      } else {
        if (
          "File" in window &&
          this.videoPreviewImage instanceof File == false
        ) {
          this.videoPreviewImageError.status = false;
          this.videoPreviewImageError.message =
            "Please upload a valid file as the video preview file";

          errorList.push(
            "Please upload a valid file as the video preview file"
          );
        }
        if (
          this.videoPreviewImage.type &&
          this.videoPreviewImage.type != "image/jpeg" &&
          this.videoPreviewImage.type != "image/png"
        ) {
          this.videoPreviewImageError.status = false;
          this.videoPreviewImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";

          errorList.push(
            "Please upload a valid image file in either .jpeg or .png formats"
          );
        }
        if (
          this.videoPreviewImage.size &&
          this.videoPreviewImage.size > 5 * 1024 * 1024
        ) {
          this.videoPreviewImageError.status = false;
          this.videoPreviewImageError.message =
            "Please upload an image file that is less than 50MB in size";

          errorList.push(
            "Please upload an image file that is less than 50MB in size"
          );
        }
      }

      // overall error message
      let payloadNotify = {
        isToast: true,
        title: "Error: Unable to save video",
        content: "Please resolve the following:",
        variant: "danger",
        list: errorList,
        duration: "long",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    resetFormFn() {
      // reset the data parameters
      this.videoTitle = null;
      this.videoFile = null;
      this.videoPreviewImage = null;

      // reset the loading states
      this.isAPILoading = false;

      // reset any other text
      this.apiConfirmationText = null;

      // reset error parameters
      this.videoTitleError = {
        status: null,
        message: "",
      };
      this.videoFileError = {
        status: null,
        message: "",
      };
      this.videoPreviewImageError = {
        status: null,
        message: "",
      };
    },
  },
};
</script>

<style scoped>
.filter-dropdown-element {
  height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
/* truncate text */
.filter-dropdown-element >>> .filter-dropdown-button-element > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-dropdown-element >>> .filter-dropdown-menu {
  min-width: 250px;
  margin-top: 10px;
  border-radius: 5px;
  max-height: 105px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* dropdown search element */
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input {
  box-shadow: none;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input::placeholder {
  color: var(--primary);
  font-weight: bold;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-item-button
  > span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-dropdown-element
  >>> .filter-dropdown-menu
  .dropdown-search-input-group
  > input.dropdown-search-input::placeholder {
  color: var(--secondary);
  font-weight: bold;
  box-shadow: var(--secondary);
}
/* container card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px;
  border: 1px solid var(--light);
}

.form-file {
  color: var(--prime-gray);
  border: violet;
}

/* placeholder text color */
.form-file >>> .form-control::placeholder {
  color: var(--prime-gray);
}

/* custom checkbox */
.custom-checkbox,
.custom-checkbox-active {
  cursor: pointer;
}

/* checkbox color (default state) */
.custom-checkbox >>> .custom-control-label,
.custom-checkbox-active >>> .custom-control-label {
  user-select: none;
}

.custom-checkbox >>> .custom-control-label::before {
  background-color: var(--ghost-white);
  border-color: transparent;
}

.custom-checkbox >>> .custom-control-label::after {
  background-color: var(--white);
}

/* override default bootstrap styles */
.custom-checkbox
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

/* checkbox color (active state) */
.custom-checkbox-active >>> .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

/* override default bootstrap styles */
.custom-checkbox-active
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox-active
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.custom-checkbox-active
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.video-preview-row {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.video-preview-row .video-placeholder {
  min-height: 300px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .video-preview-row {
    padding: 1rem 0;
  }
}
</style>
