export default [
    {
      family: "Open Sans",
      files: {
        regular: "/Fonts/OpenSansRegular.ttf",
        italic: "/Fonts/OpenSansItalic.ttf",
        bold: "/Fonts/OpenSansBold.ttf",
        bold_italic: "/Fonts/OpenSansBoldItalic.ttf",
      },
    },
    {
      family: "Times New Roman",
      files: {
        regular: "/Fonts/TimesNewRoman.ttf",
        italic: "/Fonts/TimesNewRomanItalic.ttf",
        bold: "/Fonts/TimesNewRomanBold.ttf",
        bold_italic: "/Fonts/TimesNewRomanBoldItalic.ttf",
      },
    },
    {
        family: "Arial Black",
        files: {
          regular: "/Fonts/Ariblk.ttf",
          italic: "/Fonts/ArialItalic.ttf",
          bold: "/Fonts/ArialBold.ttf",
          bold_italic: "/Fonts/ArialBoldItalic.ttf",
        },
      },
    {
      family: "Spacemono",
      files: {
        regular: "/Fonts/SpaceMonoRegular.ttf",
        italic: "/Fonts/SpaceMonoItalic.ttf",
        bold: "/Fonts/SpaceMonoBold.ttf",
        bold_italic: "/Fonts/SpaceMonoBoldItalic.ttf",
      },
    },
    {
      family: "Roboto",
      files: {
        regular: "/Fonts/RobotoRegular.ttf",
        italic: "/Fonts/RobotoItalic.ttf",
        bold: "/Fonts/RobotoBold.ttf",
        bold_italic: "/Fonts/RobotoBoldItalic.ttf",
      },
    },
    {
      family: "Comic",
      files: {
        regular: "/Fonts/ComicNeueRegular.ttf",
        italic: "/Fonts/ComicNeueItalic.ttf",
        bold: "/Fonts/ComicNeueBold.ttf",
        bold_italic: "/Fonts/ComicNeueBoldItalic.ttf",
      },
    },
    {
      family: "Garamond",
      files: {
        regular: "/Fonts/EBGaramondRegular.ttf",
        italic: "/Fonts/EBGaramondItalic.ttf",
        bold: "/Fonts/EBGaramondBold.ttf",
        bold_italic: "/Fonts/EBGaramondBoldItalic.ttf",
      },
    },
    {
      family: "Noto Serif",
      files: {
        regular: "/Fonts/NotoSerifBlack.ttf",
        italic: "/Fonts/NotoSerifBlackItalic.ttf",
        bold: "/Fonts/NotoSerifBold.ttf",
        bold_italic: "/Fonts/NotoSerifBoldItalic.ttf",
      },
    },
    {
      family: "Source Sans",
      files: {
        regular: "/Fonts/SourceSans3Regular.ttf",
        italic: "/Fonts/SourceSans3Italic.ttf",
        bold: "/Fonts/SourceSans3Bold.ttf",
        bold_italic: "/Fonts/SourceSans3BoldItalic.ttf",
      },
    },
    {
      family: "Ubuntu",
      files: {
        regular: "/Fonts/UbuntuRegular.ttf",
        italic: "/Fonts/UbuntuItalic.ttf",
        bold: "/Fonts/UbuntuBold.ttf",
        bold_italic: "/Fonts/UbuntuBoldItalic.ttf",
      },
    },
  ]